module.exports = {

  api_base_path: 'https://portal.joobster.at/portal', // beim GoLive
  portalpath: '', //GoLive

  //api_base_path: 'https://videosonneundwasser.at/portal', // beim GoLive
  //portalpath: '', //GoLive
  
  //api_base_path: '', // beim Entwickeln setzen!
  //portalpath: '/portal', // nur für Entwicklung

  //mode: "dev"
  mode: "live"
  
 
}
